import * as Sentry from '@sentry/nextjs';
import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';
import { getSession, signOut } from 'next-auth/react';

export interface PodiErrorResponse<T = unknown> {
  detail: string;
  instance: string;
  status: number;
  title: string;
  type: string;
  errors?: T;
}

// light wrapper to ensure call always resolves (so that we dont need to handle error path)
export const getSafeSessionToken = (): Promise<string | undefined> => {
  return new Promise((resolve) => {
    getSession()
      .then((session) => {
        resolve(session?.user?.accessToken);
      })
      .catch(() => {
        resolve(undefined);
      });
  });
};

// wrapper to handle adding auth header + adding api base url (if none provided)
export const podiAxios = <T>(url: string, config?: Omit<AxiosRequestConfig, 'url'>): Promise<T> => {
  const _config: AxiosRequestConfig<T> = {
    url: url?.startsWith('http') ? url : `${process.env.NEXT_PUBLIC_BASE_URL}${url}`,
    method: config?.method || 'GET',
    responseType: 'json',
    ...config
  };

  return getSafeSessionToken().then((token) => {
    if (token) {
      _config.headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ..._config.headers
      };
    }

    return axios<T>(_config)
      .then((res) => {
        if (process.env.NEXT_PUBLIC_DEBUG_API_REQUESTS_AND_RESPONSES === 'true') {
          console.info('Server API response from ' + url, res);
        }

        return res.data;
      })
      .catch(async (error: Error | AxiosError) => {
        console.error((error as AxiosError).config?.url, '\n', (error as AxiosError).message);

        if ((error as AxiosError)?.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          Sentry.captureException(error, {
            extra: {
              data: (error as AxiosError).response?.data,
              status: (error as AxiosError).response?.status,
              headers: (error as AxiosError).response?.headers
            }
          });
        } else if ((error as AxiosError).request) {
          // The request was made but no response was received
          Sentry.captureException(error, {
            extra: {
              request: (error as AxiosError).request
            }
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          Sentry.captureException(error);
        }

        if (axios.isAxiosError(error) && error.response?.status === 401) {
          await signOut({ redirect: true, callbackUrl: '/signin?error=SessionRequired' });
        }

        throw error as AxiosError<PodiErrorResponse>;
      });
  });
};
