import { gql } from '@apollo/client';

export const PATIENT_LOOKUP = gql`
  query GetPatientLookupValues($customerId: Int!) {
    customer(customerId: $customerId) {
      audioImpairment(input: { skip: 0, take: 100 }) {
        data {
          name
          description
          audioImpairmentId
        }
      }
      languagePreferences(input: { skip: 0, take: 100 }) {
        data {
          name
          description
          languagePreferenceId
        }
      }
      timeOfDayPreferences(input: { skip: 0, take: 100 }) {
        data {
          name
          description
          timeOfDayPreferenceId
        }
      }
    }
  }
`;

export const BASE_PATIENT = `
  query GetPatient(
    $customerId: Int!
    $patientId: Int!
    $podRoleIds: [Int!]
    $assignedUsersByPodRoleInput: AssignedUsersByPodRoleInput!
    $patientIdentifierInput: PatientIdentifierInput!
  ) {
    customer(customerId: $customerId) {
      name
      customerId
      crisisCallContent
      onboardingModel
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        updatedInfo {
          user {
            firstName
            lastName
          }
          updated
        }
        firstName
        lastName
        patientId
        genderId
        nickName
        designation
        lastFourSsn
        siteId
        dateOfBirth
        age
        genderIdentity
        genderPronouns
        languagePreferenceId
        otherLanguagePreference
        bestTimeToCallId
        bestDayToCallId
        preferredModeOfContactId
        hasCaregiver
        livesAlone
        livesWith
        bestPersonToContact
        timeZoneId
        employmentStatusId
        employmentType
        isVisuallyImpaired
        audioImpairmentId
        usesGlassesOrContacts
        hasRequestedDoNotCall
        offloadingCallsOnly
        temperatureThresholdOverride
        reengagementDaysOverride
        snoozedTill
        resetTrainingProtocolFromDate
        currentTrainingCadence
        note
        created
        updatedByUserId
        isDeleted
        rowVersion
        resetInitialOutreachProtocolFromDate
        offloadingCallDelay
        noOffloadingCheckin
        checkinCallDelay
        podId
        legacyUniqueId
        primaryNurseUserId
        isInterpreterServicesNeeded
        pod {
          name
          nurses: assignedUsersByPodRole(
            podRoleIds: $podRoleIds
            input: $assignedUsersByPodRoleInput
          ) {
            count
            data {
              userId
              displayName
            }
          }
        }
        primaryNurseUser {
          userId
          displayName
        }
        site {
          name
        }
        patientIdentifiers(input: $patientIdentifierInput) {
          count
          data {
            patientIdentifierId
            identifierValue
            identifierType {
              identifierTypeId
              description
              name
            }
          }
        }
      }
    }
  }
`;

export const PATIENT = gql`
  query GetPatient(
    $customerId: Int!
    $patientId: Int!
    $podRoleIds: [Int!]
    $assignedUsersByPodRoleInput: AssignedUsersByPodRoleInput!
    $patientIdentifierInput: PatientIdentifierInput!
  ) {
    customer(customerId: $customerId) {
      name
      customerId
      crisisCallContent
      onboardingModel
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        updatedInfo {
          user {
            firstName
            lastName
          }
          updated
        }
        firstName
        lastName
        patientId
        genderId
        nickName
        designation
        lastFourSsn
        siteId
        dateOfBirth
        age
        genderIdentity
        genderPronouns
        languagePreferenceId
        otherLanguagePreference
        bestTimeToCallId
        bestDayToCallId
        preferredModeOfContactId
        hasCaregiver
        livesAlone
        livesWith
        bestPersonToContact
        timeZoneId
        employmentStatusId
        employmentType
        isVisuallyImpaired
        audioImpairmentId
        usesGlassesOrContacts
        hasRequestedDoNotCall
        offloadingCallsOnly
        temperatureThresholdOverride
        reengagementDaysOverride
        snoozedTill
        resetTrainingProtocolFromDate
        currentTrainingCadence
        note
        created
        updatedByUserId
        isDeleted
        rowVersion
        resetInitialOutreachProtocolFromDate
        offloadingCallDelay
        noOffloadingCheckin
        checkinCallDelay
        podId
        legacyUniqueId
        primaryNurseUserId
        isInterpreterServicesNeeded
        pod {
          name
          nurses: assignedUsersByPodRole(podRoleIds: $podRoleIds, input: $assignedUsersByPodRoleInput) {
            count
            data {
              userId
              displayName
            }
          }
        }
        primaryNurseUser {
          userId
          displayName
        }
        site {
          name
        }
        patientIdentifiers(input: $patientIdentifierInput) {
          count
          data {
            patientIdentifierId
            identifierValue
            identifierType {
              identifierTypeId
              description
              name
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PREFFERED_MODE_OF_CONTACT = gql`
  mutation UpdatePatientPreferredModeOfContact($input: UpdatePatientPreferredModeOfContactInput!) {
    payload: updatePatientPreferredModeOfContact(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const ADD_PATIENT_CLINICAL_NOTES = gql`
  mutation AddPatientClinicalNote($input: AddPatientClinicalNoteInput!) {
    payload: addPatientClinicalNote(input: $input) {
      error
      data
      errorCode
    }
  }
`;
export const GET_CLINICAL_HISTORY = gql`
  query GetClinicalHistory($customerId: Int!, $patientId: Int!, $patientHistoryTab: PatientHistoryTab!) {
    customer(customerId: $customerId) {
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        firstName
        lastName
        patientId
        patientHistory(patientHistoryTab: $patientHistoryTab) {
          clinicalHistory {
            interactionDateTime
            interactionType {
              description
            }
            updatedByUser {
              displayName
              avatarURL
            }
            data
          }
        }
      }
    }
  }
`;
export const GET_SINCE_PREVIOUS_CALL = gql`
  query GetSincePreviousCall($customerId: Int!, $patientId: Int!, $patientHistoryTab: PatientHistoryTab!) {
    customer(customerId: $customerId) {
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        firstName
        lastName
        patientId
        patientHistory(patientHistoryTab: $patientHistoryTab) {
          sincePreviousCall {
            interactionType {
              description
            }
            updatedByUser {
              displayName
              avatarURL
            }
            interactionDateTime
            sincePreviousCallTrigger
            sincePreviousCallAdditionalInformation {
              sincePreviousCallHaveYouBeenToER
              sincePreviousCallApproximateDateOfVisit
              sincePreviousCallApproximateDateOfVisitFreeText
              sincePreviousCallReasonForVisits
              sincePreviousCallDischargeDisposition
              sincePreviousCallDischargeDispositionOther
              sincePreviousCallHaveYouBeenAdmittedHospital
              sincePreviousCallAdmittedDateOfVisit
              sincePreviousCallAdmittedDateOfVisitFreeText
              sincePreviousCallAdmittedReasonForVisits
              sincePreviousCallAdmittedDischargeDisposition
              sincePreviousCallAdmittedDischargeDispositionOther
              sincePreviousCallLowerLimbAmputations
              sincePreviousCallLocationOfAmputation
              sincePreviousCallDateOfSurgery
              sincePreviousCallDateOfSurgeryFreeText
              sincePreviousCallHaveYouHadAnyFalls
              sincePreviousCallDateOfFall
              sincePreviousCallDateOfFallFreeText
              sincePreviousCallAnyInjury
              sincePreviousCallDescribeInjury
              sincePreviousCallHaveYouHadSurgicalProceduresFoot
              sincePreviousCallLocationOfSurgery
              sincePreviousCallTypeOfProcedure
              sincePreviousCallFootDateOfSurgery
              sincePreviousCallFootDateOfSurgeryFreeText
              sincePreviousCallHaveYouHadSurgicalProcedures
              sincePreviousCallSurgicalLocationOfSurgery
              sincePreviousCallSurgicalTypeOfProcedure
              sincePreviousCallSurgicalDateOfSurgery
              sincePreviousCallSurgicalDateOfSurgeryFreeText
              sincePreviousCallNewMedicalDiagnosis
              sincePreviousCallNewMedicalDiagnosisFreetext
              sincePreviousCallNewBehavorialHealthConcerns
              sincePreviousCallNewBehavorialDescribeConcernsFreeText
              sincePreviousCallNewBehavorialReceivingTreatment
              sincePreviousCallNewSDOHConcerns
              sincePreviousCallNewDescribeConcernsFreeText
            }
          }
        }
      }
    }
  }
`;

export const GET_SUBJECTIVE_QUESTION = gql`
  query GetSubjectiveQuestion($customerId: Int!, $patientId: Int!, $patientHistoryTab: PatientHistoryTab!) {
    customer(customerId: $customerId) {
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        firstName
        lastName
        patientId
        patientHistory(patientHistoryTab: $patientHistoryTab) {
          subjectiveQuestion {
            interactionType {
              description
            }
            updatedByUser {
              displayName
              avatarURL
            }
            interactionDateTime
            subjectiveQuestionsTrigger
            subjectiveQuestionsFindingsAssessment {
              subjectiveQuestionDescribeFinding
              subjectiveQuestionDescribeFindingOther
              subjectiveQuestionLocation
              subjectiveQuestionFirstNotice
              subjectiveQuestionFirstNoticeFreeText
              subjectiveQuestionHowBig
              subjectiveQuestionHowBigOther
              subjectiveQuestionWhatCausedFinding
              subjectiveQuestionWhatCausedFindingOther
              subjectiveQuestionWoundBlister {
                subjectiveQuestionWoundBlisterIsOpen
                subjectiveQuestionWoundBlisterIsDraining
                subjectiveQuestionWoundBlisterColorDrainage
                subjectiveQuestionWoundBlisterColorDrainageOther
                subjectiveQuestionWoundBlisterThicknessDrainage
                subjectiveQuestionWoundBlisterIsThereAnOdor
                subjectiveQuestionWoundBlisterIsThereRedness
                subjectiveQuestionWoundBlisterIsEdgeWet
                subjectiveQuestionWoundBlisterAnyFlu
                subjectiveQuestionWoundBlisterBloodSugar
                subjectiveQuestionWoundBlisterProviderAware
                subjectiveQuestionWoundBlisterTreatment
                subjectiveQuestionWoundBlisterGettingBetter
                subjectiveQuestionWoundBlisterLastPrescribedShoes
                subjectiveQuestionWoundBlisterWearingPrescribedShoes
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_OTHER_QUESTION = gql`
  query GetOtherQuestion($customerId: Int!, $patientId: Int!, $patientHistoryTab: PatientHistoryTab!) {
    customer(customerId: $customerId) {
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        firstName
        lastName
        patientId
        patientHistory(patientHistoryTab: $patientHistoryTab) {
          otherQuestion {
            interactionType {
              description
            }
            updatedByUser {
              displayName
              avatarURL
            }
            interactionDateTime
            nonFootRelatedClinicalSignificantInfo
            nonFootRelatedClinicalSignificantInfoAdditionalInfo
            majorChangesRegardingHealth
            majorChangesRegardingHealthInfo
          }
        }
      }
    }
  }
`;
export const GET_SOCIAL_HISTORY = gql`
  query GetSocialHistory($customerId: Int!, $patientId: Int!, $patientHistoryTab: PatientHistoryTab!) {
    customer(customerId: $customerId) {
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        firstName
        lastName
        patientId
        patientHistory(patientHistoryTab: $patientHistoryTab) {
          socialHistory {
            interactionType {
              description
            }
            updatedByUser {
              displayName
              avatarURL
            }
            interactionDateTime
            history {
              hasFormData
              socialHistoryAmbulatoryStatus
              socialHistoryPrescribedTherapeuticShoes
              socialHistoryWearTherapeuticShoes
              socialHistoryCaregiver
              socialHistoryLivingAlone
              socialHistoryDriving
              socialHistoryVisionIssues
              socialHistoryAudioImpairment
              socialHistorySmoking
              socialHistoryWorking
              socialHistoryJob
              socialHistoryHoursOnFeet
            }
            socialHistoryFallRiskAssessment {
              hasFormData
              socialHistoryFallAssessmentFalling
              socialHistoryFallAssessmentUnsteady
              socialHistoryFallAssessmentWorried
              socialHistoryFallAssessmentInjured
            }
          }
        }
      }
    }
  }
`;

export const GET_MEDICAL_HISTORY = gql`
  query GetMedicalHistory($customerId: Int!, $patientId: Int!, $patientHistoryTab: PatientHistoryTab!) {
    customer(customerId: $customerId) {
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        firstName
        lastName
        patientId
        patientHistory(patientHistoryTab: $patientHistoryTab) {
          medicalHistoryQuestions {
            interactionType {
              description
            }
            updatedByUser {
              displayName
              avatarURL
            }
            interactionDateTime
            medicalHistorySubjectiveFinding {
              medicalHistorySubjectiveHealedWhatResulted
              medicalHistorySubjectiveFindingLocation
              medicalHistorySubjectiveFindingWhenDidItHeal
              medicalHistorySubjectiveOutcome
              medicalHistorySubjectiveHealedHowLong
              medicalHistorySubjectiveFindingDidItHeal
              medicalHistorySubjectiveHealedWhatResultedOther
            }
            medicalHistoryQuestionsFootHistoryLocation
            medicalHistoryQuestionsVascularComplications
            medicalHistoryQuestionsVascularComplicationsSelection
            medicalHistoryQuestionsDescribePain
            medicalHistoryQuestionsPainLowerExtremitiesLocation
            medicalHistoryQuestionsNeuropathyLocation
            medicalHistoryQuestionsDiabetes
            medicalHistoryQuestionsNeuropathy
            medicalHistoryQuestionsPainLowerExtremities
            medicalHistoryQuestionsPainScale
            medicalHistoryQuestionsHowLongPainLast
            medicalHistoryQuestionsAutoimmuneConditions
            medicalHistoryQuestionsDialysis
            medicalHistoryQuestionsFootHistory
            medicalHistoryQuestionsFootHistoryLocation
            medicalHistoryQuestionsWoundNow
            medicalHistoryQuestionsWoundLast
            medicalHistoryQuestionsOther
            medicalHistoryQuestionsFootUlcer
            medicalHistoryQuestionsFootUlcerLastDate
          }
        }
      }
    }
  }
`;
export const GET_SURGICAL_HISTORY = gql`
  query GetSurgicalHistory($customerId: Int!, $patientId: Int!, $patientHistoryTab: PatientHistoryTab!) {
    customer(customerId: $customerId) {
      patient(input: { skip: 0, take: 100, patientId: $patientId }) {
        firstName
        lastName
        patientId
        patientHistory(patientHistoryTab: $patientHistoryTab) {
          surgicalHistory {
            interactionType {
              description
            }
            updatedByUser {
              displayName
              avatarURL
            }
            interactionDateTime
            surgicalHistoryVascularSurgeries
            surgicalHistoryPrevious
            surgicalHistoryLingeringEffects
            surgicalHistoryAmputationHistory
            surgicalHistoryAmputationHistorySelection {
              surgicalHistoryAmputationHistoryLocation
              surgicalHistoryAmputationHistoryLocationLeftOrRight
              surgicalHistoryAmputationHistoryDiabetesRelated
              surgicalHistoryAmputationHistoryWhen
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PATIENT_PRIMARY_NURSE_USER = gql`
  mutation UpdatePatientPrimaryNurseUser($input: UpdatePatientPrimaryNurseUserInput!) {
    payload: updatePatientPrimaryNurseUser(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const GET_PATIENT_WITHOUT_POD = gql`
  query PatientsWithoutPod($input: PatientsWithoutPodInput!) {
    patientsWithoutPod(input: $input) {
      count
    }
  }
`;

export const GET_PODS = gql`
  query Pods($input: PodInput!, $podAssignmentInput: PodAssignmentInput!) {
    pods(input: $input) {
      data {
        podId
        name
        description
        isFloat
        isActive
        isDeleted
        assignments: podAssignments(input: $podAssignmentInput) {
          data {
            podAssignmentId
            user {
              userId
              firstName
              lastName
            }
            podRole {
              description
              podRoleId
            }
          }
          count
        }
      }
      count
    }
  }
`;
export const GET_PODS_V2 = `
  query Pods($input: PodInput!, $podAssignmentInput: PodAssignmentInput!) {
    pods(input: $input) {
      data {
        podId
        name
        description
        isFloat
        isActive
        isDeleted
        assignments: podAssignments(input: $podAssignmentInput) {
          data {
            podAssignmentId
            user {
              userId
              firstName
              lastName
            }
            podRole {
              description
              podRoleId
            }
          }
          count
        }
      }
      count
    }
  }
`;

export const GET_WORKLIST_TYPES_BY_ASSIGNED_TO_USER_ID = gql`
  query ($input: WorklistTypesByAssignedToUserIdInput!) {
    worklistTypesByAssignedToUserId(input: $input) {
      data {
        id
        resultCount
        type
        assignedToUserId
        podName
        podId
        summaryType
        summaryName
        lastUpdated
      }
      count
    }
  }
`;

export const GET_ESCALATION_TYPE_BY_ASSIGNED_TO_USER_ID = gql`
  query ($input: EscalationTypeByAssignedToUserIdInput!) {
    escalationTypeByAssignedToUserId(input: $input) {
      data {
        id
        resultCount
        escalationType
        assignedToUserId
        podName
        podId
        summaryType
        summaryName
        lastUpdated
      }
      count
    }
  }
`;

export const NOT_CALLED_SINCE_BUCKET_BY_PRIMARY_NURSE_USER_ID_DETAILS = gql`
  query ($input: NotCalledSinceBucketByPrimaryNurseUserIdDetailsInput!) {
    notCalledSinceBucketByPrimaryNurseUserIdDetails(input: $input) {
      data {
        patientId
        patientFirstName
        patientLastName
        patientMiddleName
        patientDisplayName
        patientDesignation
        customerId
        customerName
        lastCalledDaysAgo
        notCalledSinceBucket
        primaryNurseUserId
        podName
        podId
      }
      count
    }
  }
`;

export const NOT_CALLED_SINCE_BUCKET_BY_PRIMARY_NURSE_USER_ID = gql`
  query ($primaryNurseUserId: Int!, $input: NotCalledSinceBucketByPrimaryNurseUserIdInput!) {
    notCalledSinceBucketByPrimaryNurseUserId(primaryNurseUserId: $primaryNurseUserId, input: $input) {
      data {
        id
        resultCount
        notCalledSinceBucket
        primaryNurseUserId
        podName
        podId
        summaryType
        summaryName
        lastUpdated
      }
      count
    }
  }
`;

export const GET_PATIENT_ESCALATION = gql`
  query GetPatient($customerId: Int!, $patientId: Int!) {
    customer(customerId: $customerId) {
      customerId
      patient(input: { skip: 0, take: 1, patientId: $patientId }) {
        patientHistory(patientHistoryTab: MEDICAL_HISTORY_QUESTIONS) {
          patientInteractionForms {
            nextClinicVisit
            nextClinicVisitNote
          }
        }
        patientProviders(input: { skip: 0, take: 1 }) {
          data {
            providerId
            provider {
              name
              nPI
            }
          }
        }
        currentWorklistItem {
          note
          escalation {
            escalationId
            subjectiveQuestion {
              interactionDateTime
              nextClinicVisit
              nextClinicVisitNote
              lastClinicVisit
              lastClinicVisitNote
              subjectiveQuestionsTrigger
              subjectiveQuestionsFindingsAssessment {
                subjectiveQuestionDescribeFinding
                subjectiveQuestionDescribeFindingOther
                subjectiveQuestionLocation
                subjectiveQuestionFirstNotice
                subjectiveQuestionFirstNoticeFreeText
                subjectiveQuestionHowBig
                subjectiveQuestionHowBigOther
                subjectiveQuestionWhatCausedFinding
                subjectiveQuestionWhatCausedFindingOther
                subjectiveQuestionWoundBlister {
                  subjectiveQuestionWoundBlisterIsOpen
                  subjectiveQuestionWoundBlisterIsDraining
                  subjectiveQuestionWoundBlisterColorDrainage
                  subjectiveQuestionWoundBlisterColorDrainageOther
                  subjectiveQuestionWoundBlisterThicknessDrainage
                  subjectiveQuestionWoundBlisterIsThereAnOdor
                  subjectiveQuestionWoundBlisterIsThereRedness
                  subjectiveQuestionWoundBlisterIsEdgeWet
                  subjectiveQuestionWoundBlisterAnyFlu
                  subjectiveQuestionWoundBlisterBloodSugar
                  subjectiveQuestionWoundBlisterProviderAware
                  subjectiveQuestionWoundBlisterTreatment
                  subjectiveQuestionWoundBlisterGettingBetter
                  subjectiveQuestionWoundBlisterLastPrescribedShoes
                  subjectiveQuestionWoundBlisterWearingPrescribedShoes
                }
              }
            }
          }
          subjectiveQuestion {
            interactionDateTime
            nextClinicVisit
            nextClinicVisitNote
            lastClinicVisit
            lastClinicVisitNote
            subjectiveQuestionsTrigger
            subjectiveQuestionsFindingsAssessment {
              subjectiveQuestionDescribeFinding
              subjectiveQuestionDescribeFindingOther
              subjectiveQuestionLocation
              subjectiveQuestionFirstNotice
              subjectiveQuestionFirstNoticeFreeText
              subjectiveQuestionHowBig
              subjectiveQuestionHowBigOther
              subjectiveQuestionWhatCausedFinding
              subjectiveQuestionWhatCausedFindingOther
              subjectiveQuestionWoundBlister {
                subjectiveQuestionWoundBlisterIsOpen
                subjectiveQuestionWoundBlisterIsDraining
                subjectiveQuestionWoundBlisterColorDrainage
                subjectiveQuestionWoundBlisterColorDrainageOther
                subjectiveQuestionWoundBlisterThicknessDrainage
                subjectiveQuestionWoundBlisterIsThereAnOdor
                subjectiveQuestionWoundBlisterIsThereRedness
                subjectiveQuestionWoundBlisterIsEdgeWet
                subjectiveQuestionWoundBlisterAnyFlu
                subjectiveQuestionWoundBlisterBloodSugar
                subjectiveQuestionWoundBlisterProviderAware
                subjectiveQuestionWoundBlisterTreatment
                subjectiveQuestionWoundBlisterGettingBetter
                subjectiveQuestionWoundBlisterLastPrescribedShoes
                subjectiveQuestionWoundBlisterWearingPrescribedShoes
              }
            }
          }
        }
        firstName
        lastName
        patientId
        patientIdentifiers(input: { skip: 0, take: 1 }) {
          data {
            patientIdentifierId
            patientId
            identifierTypeId
            identifierValue
          }
        }
      }
    }
  }
`;
export const GET_PODS_LIST = gql`
  query PodsList($input: PodInput!) {
    podList: pods(input: $input) {
      data {
        podId
        name
        description
        isFloat
        isActive
        isDeleted
      }
      count
    }
  }
`;

export const SAVE_POD = gql`
  mutation AddPod($input: AddPodInput!) {
    addPod(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const DELETE_POD = gql`
  mutation DeletePod($podId: Int!, $userId: Int!) {
    deletePod(input: { podId: $podId, userId: $userId }) {
      error
    }
  }
`;

export const GET_POD_ASSIGNMENTS = gql`
  query GetPodAssignments($podId: Int!, $podAssignmentInput: PodAssignmentInput!) {
    pod(podId: $podId) {
      podId
      name
      description
      assignments: podAssignments(input: $podAssignmentInput) {
        data {
          podAssignmentId
          user {
            userId
            firstName
            lastName
          }
          podRole {
            description
            podRoleId
          }
        }
        count
      }
    }
  }
`;

export const GET_POD_ROLES = gql`
  query GetPodRoles($podId: Int!) {
    pod(podId: $podId) {
      podRoles {
        data {
          podRoleId
          description
          name
        }
      }
    }
  }
`;

export const GET_USERS_BY_POD_ROLE = gql`
  query GetUsersByPodRole($podId: Int!, $podRoleId: Int!, $userInput: PodRoleUserInput!) {
    pod(podId: $podId) {
      podRole(podRoleId: $podRoleId, podId: $podId) {
        podRoleId
        users(input: $userInput) {
          count
          data {
            userId
            firstName
            lastName
            avatarURL
            email
          }
        }
      }
    }
  }
`;

export const ADD_POD_ASSIGNMENTS = gql`
  mutation AddPodAssignments($input: AddPodAssignmentListInput!) {
    payload: addPodAssignmentList(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const GET_POD_PATIENTS = gql`
  query GetPodPatients($podId: Int!, $podPatientInput: PodPatientInput!, $podRoleIds: [Int!], $assignedUsersByPodRoleInput: AssignedUsersByPodRoleInput!) {
    pod(podId: $podId) {
      podId
      name
      description
      patients(input: $podPatientInput) {
        data {
          customerId
          customerName
          patientId
          patientFirstName
          patientLastName
          primaryNurseUser {
            displayName
            userId
          }
          pod {
            name
            nurses: assignedUsersByPodRole(podRoleIds: $podRoleIds, input: $assignedUsersByPodRoleInput) {
              count
              data {
                userId
                displayName
              }
            }
          }
        }
        count
      }
    }
  }
`;

export const GET_CUSTOMER_LIST = gql`
  query GetCustomersList($input: CustomerInput!) {
    customers(input: $input) {
      count
      data {
        customerId
        name
      }
    }
  }
`;

export const GET_CUSTOMER_PATIENT_LIST = gql`
  query GetCustomerPatientList($customerId: Int!, $patientInput: PatientInput!) {
    customer(customerId: $customerId) {
      customerId
      patientsWithoutPod(input: $patientInput) {
        count
        data {
          patientId
          firstName: patientFirstName
          lastName: patientLastName
        }
      }
    }
  }
`;

export const GET_CUSTOMER_SITE_LIST = gql`
  query GetCustomerSiteList($customerId: Int!, $siteInput: SiteInput!) {
    customer(customerId: $customerId) {
      customerId
      sites(input: $siteInput) {
        count
        data {
          siteId
          name
        }
      }
    }
  }
`;

export const GET_CUSTOMER_SITE_PATIENT_LIST = gql`
  query GetCustomerSitePatientList($customerId: Int!, $siteId: Int!, $patientInput: PatientInput!) {
    customer(customerId: $customerId) {
      customerId
      site(siteId: $siteId) {
        patientsWithoutPod(input: $patientInput) {
          count
          data {
            patientId
            firstName: patientFirstName
            lastName: patientLastName
          }
        }
      }
    }
  }
`;
export const ADD_POD_PATIENT = gql`
  mutation AddPodPatients($input: AddPodPatientsInput) {
    payload: addPodPatients(input: $input) {
      data
      error
      errorCode
    }
  }
`;
export const ADD_SITE_PATIENT_TO_POD = gql`
  mutation AddSitePatientsToPod($input: AddSitePatientsToPodInput) {
    payload: addSitePatientsToPod(input: $input) {
      data
      error
      errorCode
    }
  }
`;
export const ADD_CUSTOMER_PATIENT_TO_POD = gql`
  mutation AddCustomerPatientsToPod($input: AddCustomerPatientsToPodInput) {
    payload: addCustomerPatientsToPod(input: $input) {
      data
      error
      errorCode
    }
  }
`;
export const GET_POD_CUSTOMER_LIST = gql`
  query GetPodCustomersList($podId: Int!, $customerInput: CustomerInput!) {
    pod(podId: $podId) {
      customers(input: $customerInput) {
        data {
          customerId
          name
        }
      }
    }
  }
`;

export const GET_POD_CUSTOMER_SITE_LIST = gql`
  query GetPodCustomerSiteList($podId: Int!, $customerId: Int!, $siteInput: SiteInput!) {
    customer(customerId: $customerId) {
      podSites(input: $siteInput, podId: $podId) {
        data {
          siteId
          name
        }
      }
    }
  }
`;

export const GET_POD_CUSTOMER_PATIENT_LIST = gql`
  query GetPodCustomerPatientList($podId: Int!, $customerId: Int!, $patientInput: PatientInput!) {
    customer(customerId: $customerId) {
      patientsWithPod(input: $patientInput, podId: $podId) {
        count
        data {
          patientId
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_POD_CUSTOMER_SITE_PATIENT_LIST = gql`
  query GetPodCustomerSitePatientList($podId: Int!, $customerId: Int!, $siteId: Int!, $patientInput: PatientInput!) {
    customer(customerId: $customerId) {
      customerId
      site(siteId: $siteId) {
        patientsWithPod(input: $patientInput, podId: $podId) {
          count
          data {
            patientId
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const DELETE_SITE_PATIENTS_FROM_POD = gql`
  mutation DeleteSitePatientsToPod($input: DeleteSitePatientsToPodInput!) {
    payload: deleteSitePatientsToPod(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const DELETE_PATIENT_FROM_POD = gql`
  mutation DeletePodPatients($input: DeletePodPatientsInput!) {
    payload: deletePodPatients(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const DELETE_CUSTOMER_PATIENT_FROM_POD = gql`
  mutation DeleteCustomerPatientsToPod($input: DeleteCustomerPatientsToPodInput!) {
    payload: deleteCustomerPatientsToPod(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const UPDATE_POD = gql`
  mutation UpdatePod($input: UpdatePodInput!) {
    updatePod(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const DELETE_POD_ASSIGNMENTS = gql`
  mutation DeletePodAssignments($input: DeletePodAssignmentsInput!) {
    payload: deletePodAssignments(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const BULK_NURSE_ASSIGNMENT = gql`
  mutation UpdateBulkPatientPrimaryNurseUser($input: UpdateBulkPatientPrimaryNurseUserInput!) {
    payload: updateBulkPatientPrimaryNurseUser(input: $input) {
      data
      error
      errorCode
    }
  }
`;

export const GET_POD_INFO = gql`
  query POD(
    $podId: Int!
    $podRoleIds: [Int!]
    $assignedUsersByPodRoleInput: AssignedUsersByPodRoleInput!
    $customerInput: CustomerInput!
    $siteInput: SiteInput!
  ) {
    pod(podId: $podId) {
      podId
      name
      description
      customers(input: $customerInput) {
        data {
          customerId
          name
          sites(input: $siteInput) {
            data {
              siteId
              name
            }
          }
        }
      }
      nurses: assignedUsersByPodRole(podRoleIds: $podRoleIds, input: $assignedUsersByPodRoleInput) {
        count
        data {
          userId
          displayName
        }
      }
    }
  }
`;

export const UPDATE_PRIMARY_NURSE_SINGLE = gql`
  mutation UpdatePatientPrimaryNurseUser($input: UpdatePatientPrimaryNurseUserInput!) {
    payload: updatePatientPrimaryNurseUser(input: $input) {
      data
      error
      errorCode
    }
  }
`;
