import { podiAxios } from '@/utils/api';
import { JSONSafeParse } from '@/utils/string';
import { HOUR } from '@/utils/time';
import { type NextAuthOptions } from 'next-auth';
import Google from 'next-auth/providers/google';
import Okta from 'next-auth/providers/okta';

const decodePodiJwt = (
  token: string
): {
  exp: string;
  iss: string;
  nbf: string;
  aud: string;
} | null => {
  if (!token) return null;

  const base64Payload = token.split('.')[1] as string;
  if (!base64Payload) return null;

  const payloadBuffer = Buffer.from(base64Payload, 'base64');
  return JSONSafeParse<{
    exp: string;
    iss: string;
    nbf: string;
    aud: string;
  } | null>(payloadBuffer.toString('utf-8'));
};

export const authOptions: NextAuthOptions = {
  providers: [
    Google({
      clientId: process.env.GOOGLE_CLIENT_ID as string,
      clientSecret: process.env.GOOGLE_CLIENT_SECRET as string
    }),
    Okta({
      clientId: process.env.NEXT_OKTA_CLIENT_ID as string,
      clientSecret: process.env.OKTA_CLIENT_SECRET as string,
      issuer: process.env.OKTA_DOMAIN as string,
      idToken: true
    })
  ],
  secret: process.env.SECRET as string,
  pages: {
    signIn: '/signin',
    error: '/signin'
  },
  session: { maxAge: 12 * HOUR }, // 12 hours (JWT maxAge also defaults to this value)
  callbacks: {
    async signIn({ account }) {
      try {
        if (account?.provider === 'google') {
          const jwt = await podiAxios<{ token: string }>('/auth', { method: 'POST', data: { token: account.id_token } }).then((response) => response.token);

          const extractedData = decodePodiJwt(jwt);

          if (!extractedData) {
            throw new Error('Invalid token');
          }

          const nowInSeconds = new Date().getTime() / 1000;
          if (!extractedData.exp && nowInSeconds > Number(extractedData.exp)) {
            throw new Error('Expired token');
          }

          console.info(`Token will expire at ${new Date(Number(extractedData.exp) * 1000)}`);

          account.id_token = jwt;
          account.expires_at = Number(extractedData.exp);
          account.podiTokenExp = Number(extractedData.exp);
          account.podiTokenIss = extractedData.iss;
          account.podiTokenNbf = Number(extractedData.nbf);
          account.podiTokenAud = extractedData.aud;

          return true;
        }

        if (account?.provider === 'okta') {
          // const userClaimsCheck = async () => {
          //   const options = {
          //     method: 'GET',
          //     headers: {
          //       'Content-Type': 'application/json',
          //       Authorization: `Bearer ${account.access_token}`
          //     }
          //   };
          //   try {
          //     const response = await fetch(`${BASE_API_URL}/api/User/Current/Claims`, options);

          //     if (!response.ok) {
          //       if (response.status === 401) {
          //         isAuthorized = false;
          //         throw new Error('Unauthorized access');
          //       } else {
          //         isAuthorized = false;
          //         throw new Error('Network response was not ok');
          //       }
          //     }
          //   } catch (error) {
          //     console.error('Podi authentication error: ', error);
          //   }
          // };
          // await userClaimsCheck();
          return false;
        }

        return false;
      } catch (error) {
        console.error('Error during sign in', error);
        throw new Error('Error during sign in');
      }
    },

    async jwt({ token, account, user }) {
      if (account?.provider === 'google') {
        token = {
          accessToken: account.id_token, // Normalizing data by setting accessToken value to be the same as idToken
          nbf: account.podiTokenNbf,
          exp: account.podiTokenExp,
          sub: account.podiTokenSub,
          aud: account.podiTokenAud,
          iss: account.podiTokenIss
        };
      }

      if (account?.provider === 'okta') {
        token = {
          accessToken: account.access_token,
          oktaId: account.providerAccountId,
          exp: account.expires_at
        };
      }

      // Check for token expiration (on session check)
      const dateNowInSeconds = new Date().getTime() / 1000;
      if (dateNowInSeconds > Number(token?.exp)) {
        throw Error('expired token');
      }

      return { ...token, ...user };
    },

    async session({ session, token }) {
      // Pass token to session.user for client-side access
      if (token) {
        session.user = token as any; // this is weird
      }
      return session;
    }
  }
};
