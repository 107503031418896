import { authOptions } from '@/api/auth/[...nextauth]/authOptions';
import { ApolloClient, HttpLink, InMemoryCache, type NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Agent } from 'https';
import { getServerSession } from 'next-auth';
import { getSession } from 'next-auth/react';
import { v4 as uuidv4 } from 'uuid';

const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_BASE_URL + '/graphql';

const agent = new Agent({
  rejectUnauthorized: false
});

const httpLink = new HttpLink({
  uri: GRAPHQL_ENDPOINT,
  fetch: fetch,
  fetchOptions: {
    agent: agent
  }
});

const authLink = setContext(async (_, { headers }) => {
  const session = await getSession();
  const authToken = session?.user.accessToken;

  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
      'Request-Id': uuidv4()
    }
  };
});

const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    }
  }
});

const authLinkServer = setContext(async (_, { headers }) => {
  const session = await getServerSession(authOptions);

  const authToken = session?.user.accessToken;

  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
      'Request-Id': uuidv4()
    }
  };
});

const graphQLClient = () => {
  // Creates a GraphQL Client for either server or client side fetching auth token per env
  const link = typeof window === 'undefined' ? authLinkServer : authLink;
  const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    ssrMode: true,
    link: link.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }
    }
  });
  return apolloClient;
};

export default apolloClient;
